import "./App.css";
import { Fragment, useState, useEffect } from "react";
import { ThemeProvider, Box } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import { Authentication } from "./routes/Authentication";
import { Main } from "./routes/Main";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastProvider, AuthProvider } from "./context";
import { useLocalStorage } from "./hooks";

function App() {
  const [theme, setTheme] = useState("light");
  const { setItem } = useLocalStorage();

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      const currentTime = new Date().toISOString();
      setItem("userLeaveTime", currentTime);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    console.log("🚀 ~ App version: 1.0.0.21.1, 21 Jun 2024");
  }, []);

  return (
    <Fragment>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <Box
          sx={{
            minHeight: "99vh",
          }}
        >
          <AuthProvider>
            <ToastProvider>
              <Router>
                <Main />
                <Authentication />
              </Router>
            </ToastProvider>
          </AuthProvider>
        </Box>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
