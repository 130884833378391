import { Fragment, useEffect, useState } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { MemberDetailsTab } from "../../components/tabs/MemberDetailsTab";
import { UserDetailsTab } from "./UserDetails/UserDetailsTab";
import { CustomerTransactionTable } from "../../components/table/CustomerTransactionTable";
import { IDVerificationListTable } from "../../components/table/IDVerificationListTable";
import { ProfileEditHistoryTable } from "../../components/table/ProfileEditHistoryTable";
import { SecurityLogsTable } from "../../components/table/SecurityLogsTable";
import { MoreInfoTab } from "./MoreInfo/MoreInfoTab";
import { StatusCOMTable } from "../../components/table/StatusCOMTable";
import { Typography } from "@mui/material";
import { useLocalStorage } from "../../hooks";
import { useLocation } from "react-router-dom";

export const MemberDetailsScreen = () => {
  const { getItem, setItem, removeItem } = useLocalStorage();
  const location = useLocation();
  const [userDetails, setUserDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (location.state) {
      if (location.state.clearData) {
        // Clear the clearData flag without redirecting
        const updatedState = { ...location.state };
        delete updatedState.clearData;
        window.history.replaceState(
          { ...location.state, clearData: null },
          "",
          location.pathname
        );

        // Clear the state
        setSelectedTab(0); // Reset selectedTab to default value
        removeItem("tab_id");
      } else {
        setSelectedTab(
          getItem("tab_id") !== null ? parseInt(getItem("tab_id"), 10) : 0
        );
      }
    } else {
      setSelectedTab(
        getItem("tab_id") !== null ? parseInt(getItem("tab_id"), 10) : 0
      );
    }
  }, [location.state]);

  useEffect(() => {
    setItem("tab_id", selectedTab);
  }, [selectedTab]);

  const showIDverification = () => {
    if (
      (userDetails?.scan_method ?? "") === "m" ||
      (userDetails?.scan_method ?? "") === ""
    ) {
      // scan_method === m (Microblink) &&& id_type === m (MRZ)
      if ((userDetails?.id_type ?? "") === "m") {
        return false;
      } else {
        // i or p
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <ActionBar header={"Customer Details"} showBackButton={true} />
      <PageStackLayout>
        <Typography variant="h1">Customer Details</Typography>
        {userDetails && (
          <MemberDetailsTab
            selectedTab={selectedTab}
            onSetSelectedTab={setSelectedTab}
            showEdd={userDetails?.edd_status ?? false}
            showIDverification={userDetails && showIDverification()}
          />
        )}
        {showIDverification() ? (
          selectedTab === 0 ? (
            <UserDetailsTab onSetUserDetails={(data) => setUserDetails(data)} />
          ) : selectedTab === 1 ? (
            <StatusCOMTable type={1} userDetails={userDetails} />
          ) : selectedTab === 2 ? (
            <IDVerificationListTable userDetails={userDetails} data={[]} />
          ) : selectedTab === 3 ? (
            <CustomerTransactionTable />
          ) : selectedTab === 4 ? (
            <ProfileEditHistoryTable />
          ) : selectedTab === 5 ? (
            <SecurityLogsTable />
          ) : (
            <MoreInfoTab />
          )
        ) : selectedTab === 0 ? (
          <UserDetailsTab onSetUserDetails={(data) => setUserDetails(data)} />
        ) : selectedTab === 1 ? (
          <StatusCOMTable type={1} userDetails={userDetails} />
        ) : selectedTab === 2 ? (
          <CustomerTransactionTable />
        ) : selectedTab === 3 ? (
          <ProfileEditHistoryTable />
        ) : selectedTab === 4 ? (
          <SecurityLogsTable />
        ) : (
          <MoreInfoTab />
        )}
      </PageStackLayout>
    </Fragment>
  );
};
