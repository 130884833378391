import { Fragment, useState, useEffect } from "react";
import { ActionBar } from "../../components/headers/ActionBar";
import { ArchiveUsersCATable } from "../../components/table/ArchiveUsersCATable";
import { PageStackLayout } from "../../components/layout/PageStackLayout";
import { useAuth, useToast } from "../../context";
import { useAPI, useModal } from "../../hooks";
import LoaderCard from "../../components/cards/LoaderCard";
import { Typography, Stack, Button, useTheme } from "@mui/material";
import { CheckIdenfaceAlert } from "../../components/alert/CheckIdenfaceAlert";

export const CustomerArchivesScreen = () => {
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const theme = useTheme();

  const [requestData, setRequestData] = useState({
    itemsPerPage: 25,
    currentPage: 1,
    searchText: "",
    sortData: {
      sort_field: null,
      sort_order: "asc",
    },
  });
  const [dataLoading, setDataLoading] = useState(false);

  const [customersList, setCustomersList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 0,
    total_count: 0,
  });
  const [timer, setTimer] = useState(null);
  const [isOpenCheckModal, openCheckModal, closeCheckModal] = useModal();

  //api
  const getCustomersList = async () => {
    setDataLoading(true);
    const { itemsPerPage, currentPage, searchText, sortData } = requestData;
    api
      .get(
        `${urls.customerArchives}?page=${currentPage}&limit=${itemsPerPage}&search=${searchText}&sort_field=${sortData.sort_field}&sort_order=${sortData.sort_order}`
      )
      .then((res) => {
        setDataLoading(false);
        let data = res.data.data;
        setCustomersList(data.data);
        setPaginationData(data.pagination);
      })
      .catch(handleApiError);
  };

  //error handling
  const handleApiError = (err) => {
    setDataLoading(false);
    if (err.response?.status === 401) {
      logout();
    } else {
      console.log("err", err);
      showToast(err.response?.data?.message, "error");
    }
  };

  //useEffect
  useEffect(() => {
    getCustomersList();
  }, [requestData]);

  const updateRequestData = (newData) => {
    setRequestData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  // const onSearchTextChange = (event) => {
  //   updateRequestData({
  //     searchText: event.target.value,
  //     currentPage: 1,
  //     sortData: {
  //       sort_field: null,
  //       sort_order: "asc",
  //     },
  //   });
  // };
  const onSearchTextChange = (event) => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      updateRequestData({
        searchText: event.target.value,
        currentPage: 1,
        sortData: {
          sort_field: null,
          sort_order: "asc",
        },
      });
    }, 500);

    setTimer(newTimer);
  };

  return (
    <Fragment>
      <ActionBar
        header={"Customer Archives"}
        onSearchTextChange={onSearchTextChange}
      />
      <PageStackLayout>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="h1">Customer Archives</Typography>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            onClick={openCheckModal}
            sx={{
              height: 40,
              borderRadius: 2,
              fontSize: 13,
              fontWeight: 500,
              textTransform: "none",
            }}
            size="small"
          >
            Check Status
          </Button>
        </Stack>
        {dataLoading && <LoaderCard />}
        {!dataLoading && (
          <ArchiveUsersCATable
            data={customersList}
            paginationData={paginationData}
            itemsPerPage={requestData.itemsPerPage}
            setItemsPerPage={(count) =>
              updateRequestData({ itemsPerPage: count, currentPage: 1 })
            }
            setPageNumber={(page) => updateRequestData({ currentPage: page })}
            sortData={requestData.sortData}
            setSortData={(data) => updateRequestData({ sortData: data })}
          />
        )}
      </PageStackLayout>
      <CheckIdenfaceAlert
        open={isOpenCheckModal}
        onClose={closeCheckModal}
        onConfirm={() => {
          closeCheckModal();
        }}
      />
    </Fragment>
  );
};
